import React from "react";
import {getData,getParams} from './Datas.js'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TableLine from "./TableLine.js";

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.interval = null;
        this.intervalPage = null;
        this.state = { datas: [],titre:"titre...",pageActive:1,pageTot:1 };        
      }
    
    componentDidMount(){
        this.getParamsGG()
        this.getDatasGG()
        this.interval = setInterval(this.getDatasGG,4000)
        this.intervalPage = setInterval(this.pageSuivante,20000)
    }

    pageSuivante = () => {
        var pageActive = this.state.pageActive
        var pageTot = this.state.pageTot
        pageActive = pageActive + 1
        if (pageActive>pageTot){
            pageActive = 1
        }
        this.setState({pageActive:pageActive})
    }

    componentWillUnmount(){
        console.log("CLOSE")
        clearInterval(this.interval);
    }
    getDatasGG=() => {
        console.log("UPDATE")
        getData().then(data => {
            if (data){
                var pageTot = Math.ceil(data[0].data.length/27)
                this.setState({datas:data[0].data,pageTot:pageTot})
            }
        })
    }

    getParamsGG=()=>{
        getParams().then(data => {
            if (data){
                this.setState({titre:data[0].data[0].titre})
            }

        })
    }
    
    render() {
        var col1 = [];
        for (let i = (3 + ((this.state.pageActive-1)*27)); i < (12+ ((this.state.pageActive-1)*27)); i++) {
            if (this.state.datas[i]){
                col1.push(this.state.datas[i])
            }
        }
        var col2 = [];
        for (let i = (12+ ((this.state.pageActive-1)*27)); i < (21+ ((this.state.pageActive-1)*27)); i++) {
            if (this.state.datas[i]){
                col2.push(this.state.datas[i])
            }
        }
        var col3 = [];
        for (let i = (21+ ((this.state.pageActive-1)*27)); i < (30+ ((this.state.pageActive-1)*27)); i++) {
            if (this.state.datas[i]){
                col3.push(this.state.datas[i])
            }
        }
      return (
          <>
        <div className="title">
            
            <Container fluid>
                <Row>
                <Col xs={9}>
                    {this.state.titre}
                </Col>
                <Col xs={3} className="imgContainer">
                    <img alt="logo" width="200px" src="/img/abba.jpg"/>
                </Col>
                </Row>
            </Container>
        </div>
         <div className="table_ranking">
         <Container fluid className="leaders">
            <Row>
                <Col className="bigcoloneMiddle cont">
                    {this.state.datas[0]?
                        <TableLine key={0} data={this.state.datas[0]} />
                    :null}
                </Col>
                <Col className="bigcoloneMiddle cont">
                    {this.state.datas[1]?
                        <TableLine key={1} data={this.state.datas[1]} />
                    :null}
                </Col>
                <Col className="cont">
                    {this.state.datas[2]?
                        <TableLine key={2} data={this.state.datas[2]} />
                    :null}
                </Col>

            </Row>
        </Container> 
        <div className="lineYellow"></div>
          <Container fluid>
            <Row>
            <Col className="bigcoloneMiddle">
                <Container fluid className="cont">
                    {col1.map((d,index)=>{
                        return(
                            <TableLine key={index} data={d} />
                        )
                    })}
                </Container>
            </Col>
            <Col className="bigcoloneMiddle">
                <Container fluid className="cont">
                        {col2.map((d,index)=>{
                            return(
                                <TableLine key={index} data={d} />
                            )
                        })}
                </Container>                    
            </Col>
            <Col>
                <Container fluid className="cont">
                        {col3.map((d,index)=>{
                            return(
                                <TableLine key={index} data={d} />
                            )
                        })}
                </Container>                                        
            </Col>
            </Row>
            </Container>
            </div>
        </>
      );
    }
  }

  export default Table;
