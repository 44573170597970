import { fetchGoogleSheetsData } from 'google-sheets-mapper';

export const getData = async () => {
  try {
    return await fetchGoogleSheetsData({
      apiKey: "AIzaSyBmfjbNJ5GYG9Kzy2uXPzOXuR1L4XFJ7PU",
      sheetId: "1MaVZKh69HuQ7xqtgpqPp4lHBDQTt_Rs-5mSHvkcKipY",
      sheetsNames: ['DATAS'],
    });
  } catch (error) {
    console.error(error);
  }
};
 
export const getParams = async () => {
  try {
    return await fetchGoogleSheetsData({
      apiKey: "AIzaSyBmfjbNJ5GYG9Kzy2uXPzOXuR1L4XFJ7PU",
      sheetId: "1MaVZKh69HuQ7xqtgpqPp4lHBDQTt_Rs-5mSHvkcKipY",
      sheetsNames: ['PARAMS'],
    });
  } catch (error) {
    console.error(error);
  }
};
 
