import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TableLine extends React.Component {
    truncate(str) {
        return str.length > 15 ? str.substring(0, 14) + "..." : str;
    }
    render(){
    return(
        <Row className="line">
            <Col xs={2} className="rank">{this.props.data.Rank>0?this.props.data.Rank+'.':''}</Col>
            <Col className="name">{this.truncate(this.props.data.Nom)}</Col>
            <Col xs={3} className="points">{this.props.data.Points}</Col>
        </Row>
    )
}
}
export default TableLine;
