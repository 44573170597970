import './App.css';
import React from "react";
import Table from './Table.js'

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="fill-window">
      <div className="main">
          <Table/>
      </div>
    </div>
  );
}

export default App;
